export const AREA_DEFAULT_OPTIONS = {
  chart: {
    height: 250,
    type: 'area',
    parentHeightOffset: 0,
    background: 'transparent',
    fontFamily: 'Nunito, sans-serif',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    }
  },
  dataLabels: {
    enabled: false
  },
  grid: {
    show: false,
  },
  legend: {
    showForSingleSeries: true,
    horizontalAlign: 'left',
    fontSize: '12px',
    fontWeight: 700,
    offsetX: 50,
    offsetY: 10,
    markers: {
      width: 10,
      height: 10,
      radius: 0,
      fillColors: ['#7ABCF5'],
    },
    onItemClick: {
      toggleDataSeries: false
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#7ABCF5'],
  xaxis: {
    type: 'category',
    axisBorder: {
      color: '#c0bfd2',
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    tickAmount: 3,
    labels: {
      minWidth: 65,
      style: {
        colors: [],
        fontSize: '12px',
        fontWeight: 400,
      },
      offsetX: -10,
    },
    title: {
      rotate: -90,
      offsetX: -10,
      offsetY: 0,
      style: {
        color: '#BDBDBD',
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    crosshairs: {
      show: true,
      position: 'front',
      stroke: {
        color: '#EB5757',
        width: 1,
        dashArray: 4,
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  fill: {
    colors: ['#DDF3FF'],
    type: 'gradient',
    gradient: {
      type: "vertical",
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 100],
    }
  },
}

export const DONUT_DEFAULT_OPTIONS = {
  chart: {
    type: 'donut',
    height: 250,
    background: 'transparent',
    fontFamily: 'Nunito, sans-serif',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    }
  },
  colors: ['#2D9CDB', '#32AFAB', '#FF7A00'],
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '80%',
        labels: {
          show: false,
        }
      }
    }
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      }
    },
  },
}

export const LINE_DEFAULT_OPTIONS = {
  chart: {
    height: 200,
    type: 'line',
    background: 'transparent',
    parentHeightOffset: 0,
    fontFamily: 'Nunito, sans-serif',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    }
  },
  grid: {
    show: false,
    borderColor: '#E0E0E0',
  },
  legend: {
    show: false,
    horizontalAlign: 'left',
    fontSize: '14px',
    markers: {
      radius: 2,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      style: {
        colors: '#1B203D'
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    tickAmount: 5,
    labels: {
      style: {
        colors: ['#1B203D'],
      },
    },
  },
}
