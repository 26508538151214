<template>
    <div ref="chart" class="chart dashboard-sales-trend-chart"></div>
</template>

<script>
import Apexcharts from "@/utilities/charts/apexcharts";
import { formatCurrency } from '@/utilities'

export default {
  name: 'SalesTrendChart',
  data: () => ({
    loading: true,
    chart: null,
    chartOptions: {
      chart: {
        height: 450,
        type: 'line',
      },
      dataLabels: {
        // formatter: function (val) {
        //   console.log('dataLabels val', val)
        //   return val
        // },
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        offsetX: -24,
        itemMargin: {
          horizontal: 16,
        }
      },
      colors: ['#db332d', '#9c32af', '#9500ff', '#ffa200'],
      stroke: {
        width: 3
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (value) {
            return formatCurrency(value);
          }
        }
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 32,
        labels: {
          formatter: (val, timestamp) => {
            return new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: 'numeric',
            }).format(timestamp);
          }
        },
        crosshairs: {
          show: true,
          width: 1,
          position: 'front',
          opacity: 1,
          stroke: {
            color: '#F54747',
            dashArray: 0,
          }
        }
      },
      markers: {
        colors: '#fff',
        strokeColors: ['#2ddb3e', '#3faf32', '#ff6f00', '#ffbf00'],
      },
      tooltip: {
        x: {
          formatter: (val) => {
            return new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            }).format(val);
          }
        },
        y: {
          formatter: seriesName => `${formatCurrency(seriesName)}`,
        },
      },
      series: [

        // {
        //   name: "Brellient Earth",
        //   data: [
        //     {x: "02-01-2021", y: 34},
        //     {x: "02-11-2021", y: 43},
        //     {x: "02-12-2021", y: 31},
        //     {x: "02-13-2021", y: 43},
        //     {x: "02-14-2021", y: 33},
        //     {x: "02-28-2021", y: 52}
        //   ]
        // },
        // {
        //   name: "Tiffany & Co",
        //   data: [
        //     {x: "02-01-2021", y: 24},
        //     {x: "02-11-2021", y: 12},
        //     {x: "02-12-2021", y: 11},
        //     {x: "02-13-2021", y: 14},
        //     {x: "02-14-2021", y: 37},
        //     {x: "02-28-2021", y: 13}
        //   ]
        // },
        // {
        //   name: "Cartier",
        //   data: [
        //     {x: "02-01-2021", y: 2},
        //     {x: "02-11-2021", y: 18},
        //     {x: "02-12-2021", y: 8},
        //     {x: "02-13-2021", y: 27},
        //     {x: "02-14-2021", y: 33},
        //     {x: "02-28-2021", y: 23}
        //   ]
        // }
      ],
    },
  }),
  // created() {
  //
  // },
  mounted() {
    this.$nextTick(() => {
     this.chart = new Apexcharts(this.$refs.chart, {...this.chartOptions, ...this.dataChart});
    });
  },
  props: {
    dataChart: {
      type: Object,
      default: ()=> ({
        series: []
      })
    }
  },
  destroyed() {
    if (this.chart)
      this.chart.destroy();
  }
}
</script>

<style>
.dashboard-sales-trend-chart .apexcharts-xaxis-texts-g text {
  display: none;
}

.dashboard-sales-trend-chart .apexcharts-xaxis-texts-g text:first-child,
.dashboard-sales-trend-chart .apexcharts-xaxis-texts-g text:last-child {
  display: block;
}

.dashboard-sales-trend-chart .apexcharts-xaxis-texts-g text:first-child {
  transform: translate(8%, 2%) rotate(0deg)!important;
}

.dashboard-sales-trend-chart .apexcharts-xaxis-texts-g text:last-child {
  transform: translate(0%, 2%) rotate(0deg)!important;
}

</style>
